<template>
  <div class="text">
    <div class="me"></div>
    <p>Hi, I'm Lawrence!</p>
    <p>
      I'm a <strong>technical program manager</strong> and sometimes
      <a href="https://spreadseer.com" target="_blank">fullstack developer</a>
      based in the SF Bay Area. I like to help people help people.
    </p>
    <p>
      I'm currently working at
      <strong><a href="https://figma.com/" target="_blank">Figma</a></strong>
      (<a href="https://www.figma.com/careers/" target="_blank">we're hiring</a
      >). My previous roles were at Instagram, Uber, and Poynt.
    </p>
    <hr />
    <div class="link-list">
      <div>
        <a href="mailto:contact@luk.xyz">Email</a><small>get in touch</small>
      </div>
      <div>
        <a href="https://lobby.substack.com/" target="_blank">Writing</a
        ><small>my newsletter</small>
      </div>
      <div>
        <a href="https://soundcloud.com/lawluk" target="_blank">Music</a
        ><small>my SoundCloud</small>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/lawluk/" target="_blank"
          >LinkedIn</a
        ><small>my profile</small>
      </div>
    </div>
    <div class="quote-container">
      <blockquote v-if="quote">
        {{ quote.text }}<br /><br />- {{ quote.attribution }}
      </blockquote>
    </div>
  </div>
</template>

<script>
const quotes = [
  {
    text: "Good artists copy; great artists steal.",
    attribution: "Pablo Picasso (?)",
  },
  {
    text: "There are only two ways to make money in business: One is to bundle; the other is to unbundle.",
    attribution: "Jim Barksdale",
  },
  {
    text: "I can't breathe",
    attribution: "George Floyd",
  },
  {
    text: `You see this goblet? For me this glass is already broken. I enjoy it; I drink out of it. It holds my water admirably, sometimes even reflecting the sun in beautiful patterns. If I should tap it, it has a lovely ring to it. But when I put this glass on the shelf and the wind knocks it over or my elbow brushes it off the table and it falls to the ground and shatters, I say, ‘Of course.’ When I understand that the glass is already broken, every moment with it is precious.`,
    attribution: "Achaan Chaa",
  },
  {
    text: "Ambition is an uncomfortable companion. He creates a discontent with present surroundings and achievements: he is never satisfied but always pressing forward.",
    attribution: "Lyndon B Johnson",
  },
  {
    text: "A leader’s job is not to put greatness into people, but rather to recognize that it already exists, and to create the environment where that greatness can emerge and grow.",
    attribution: "Reid Hoffman",
  },
  {
    text: "Sometimes I can feel my bones straining under the weight of all the lives I’m not living.",
    attribution: "Jonathan Safran Foer",
  },
  {
    text: "Change is the law of life. And those who look only to the past or present are certain to miss the future.",
    attribution: "John F. Kennedy",
  },
  {
    text: "I'm sure the universe is full of intelligent life. It's just been too intelligent to come here.",
    attribution: "Arthur C. Clarke",
  },
  {
    text: `It’s very easy
    to fly the company into
    the side of a hill.`,
    attribution: "Patrick Collison",
  },
  {
    text: "While ages ago agricultural land and agricultural production were worth the most, and that evolved into machines and what they produced being worth the most, digital things that have no apparent physical existence (data and information processing) are evolving to become worth the most.",
    attribution: "Ray Dalio",
  },
];

export default {
  name: "App",

  data() {
    return {
      quote: null,
    };
  },

  mounted() {
    const quoteIndex = Math.floor(Math.random() * quotes.length);
    this.quote = quotes[quoteIndex];
  },
};
</script>

<style>
@import "./assets/app.css";

#app {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.me {
  display: block;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.85);
  width: 60px;
  height: 60px;
  background-image: url("./assets/me.jpg");
  background-size: contain;
  transform: rotate(5deg);
}

.text {
  max-width: 600px;
  text-align: left;
  margin: 10px;
}

.link-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.link-list small {
  color: #888;
  display: block;
}

@media only screen and (max-height: 820px) {
  blockquote {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .link-list {
    flex-direction: column;
  }

  .link-list div {
    margin-bottom: 20px;
  }
}
</style>
